<template>
  <div>
    <div class="utils__title mb-3">
      <strong class="text-uppercase font-size-16">車両の一括作成</strong>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div v-if="branchListLoading">
              <a-spin tip="Loading..." />
            </div>
            <div v-if="!branchListLoading">
              <a-steps :current="current">
                <a-step v-for="item in steps" :key="item.title" :title="item.title" />
              </a-steps>
              <div style="margin-top: 40px; padding-bottom: 30px;">
                <div v-if="current === 0">
                  <p>テンプレートファイルをダウンロードし、作成したい車両をエクセルにて入力してください。作成しましたらページ下部よりファイルをアップロードしてください。</p>
                  <a-card title="車両の一括作成テンプレート" style="margin-bottom: 30px;">
                    <p class="text-center" style="font-size: 1.2em"><a href="templates/vehicle_template.xlsx" class="text-primary"><i class="fa fa-file-excel-o" /> テンプレートファイル</a></p>
                    <p>
                      1行目は変更しないでください。<br>
                      2行目はサンプルとしていれているので、2行目から書き換えてお使いください。<br><br>
                      ※<strong>車両名、車両番号</strong>は必須項目で、255文字以下で入力してください。<br>
                      <strong>ロガー番号</strong>は特に指示がない場合「0」を入力してください。<br>
                      <strong>車検満了日、購入年月日、任意保険満了日</strong>は任意項目で、YYYY-MM-DD の形式(例. 2022年2月1日の場合、2022-02-01)で入力してください<br>
                      以下項目の選択肢についてです。それぞれ数値を入力してください。<br>
                      <strong>アプリのみでの使用</strong>は [0: アプリ以外でも使用, 1: アプリのみでの使用]<br>
                      <strong>所有形態</strong>は [0: 未選択, 1: リース, 2: 新車, 3: 中古, 4: その他]<br>
                      <strong>ドライブレコーダー設置有無</strong>は [0: 未選択, 1: 有, 2: 無]<br>
                      <strong>支店ID</strong>は [<span v-for="item in branches" :key="item.id+item.name">{{ item.id }}: {{ item.name }}, </span>]<br>
                      <strong>車種</strong>は [<span v-for="item in carTypes" :key="item.value"><span v-if="item.value !== 0">{{ item.value }}: {{ item.label }}, </span></span>]<br>
                    </p>
                  </a-card>
                  <a-upload-dragger
                    name="file"
                    :multiple="false"
                    :beforeUpload="beforeUpload"
                  >
                    <p class="ant-upload-drag-icon">
                      <a-icon type="inbox" />
                    </p>
                    <p class="ant-upload-text">
                      クリックしてファイルを選択 または ファイルをドラッグ してください。
                    </p>
                    <p class="ant-upload-hint">
                      ファイルはテンプレートに沿って作られたものに限ります。
                    </p>
                  </a-upload-dragger>
                </div>
                <div v-if="current === 1">
                  <h5 v-if="errorCount" class="text-danger" style="margin-bottom: 20px;">入力にエラーがあります。背景に<span class="wrongCell">色がついているセル</span>を確認してください。</h5>
                  <a-table
                    :columns="columns"
                    :data-source="excelData"
                    :row-key="record => record.name + record.number"
                    :pagination="false"
                    style="margin-bottom: 30px;"
                  >
                    <span slot="branch_id" slot-scope="branch_id">{{ branch_id && branches.find((o) => o.id === branch_id) ? branches.find((o) => o.id === branch_id).name : '必須項目です。'}}</span>
                    <span slot="name" slot-scope="name">{{ name ? name : '必須項目です。'}}</span>
                    <span slot="number" slot-scope="number">{{ number ? number : '必須項目です。'}}</span>
                    <span slot="logger_id" slot-scope="logger_id">{{ logger_id || logger_id === 0 ? logger_id : '必須項目です。'}}</span>
                    <span slot="car_type" slot-scope="car_type" >{{ car_type ? carTypes.find((o) => o.value === Number(car_type)).label : '必須項目です。' }}</span>
                    <span slot="only_app" slot-scope="only_app" >{{ only_app == 0 ? 'アプリ以外でも使用' : only_app == 1 ? 'アプリのみ' : '0 ~ 1 を入力してください'}}</span>
                    <span slot="ownership" slot-scope="ownership" >{{ ownership == 0 ? '未選択' : ownership == 1 ? 'リース' : ownership == 2 ? '新車' : ownership == 3 ? '中古' : ownership == 4 ? 'その他' : '0 ~ 4 を入力してください'}}</span>
                    <span slot="drive_recorder" slot-scope="drive_recorder" >{{ drive_recorder == 0 ? '未選択' : drive_recorder == 1 ? '有' : drive_recorder == 2 ? '無' : '0 ~ 2 を入力してください'}}</span>
                    <span slot="description" slot-scope="description" style="white-space: pre-wrap;">{{ description }}</span>
                  </a-table>
                  <p v-if="!errorCount">以上のデータで作成してよろしいですか？</p>
                  <div class="steps-action">
                    <a-button v-if="!errorCount" type="primary" @click="createVehicle" :loading="createLoading">
                      作成する
                    </a-button>
                    <a-button style="margin-left: 8px" @click="prev">
                      戻る
                    </a-button>
                  </div>
                </div>
                <div v-if="current === 2">
                  <p>2番目</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import XLSX from 'xlsx'
import { enumData } from '@/services/enum'

export default {
  data() {
    return {
      current: 0,
      createLoading: false,
      branchListLoading: true,
      carTypes: enumData.carType,
      branches: [],
      steps: [
        { title: 'エクセルでデータを作成' },
        { title: 'データの確認' },
        { title: '作成' },
      ],
      columns: [
        {
          title: '支店Id',
          dataIndex: '支店Id',
          scopedSlots: { customRender: 'branch_id' },
          customCell: (text, row, index) => {
            if (!this.checkBranchId(text['支店Id'])) {
              return {
                class: 'wrongCell',
              }
            }
          },
        },
        {
          title: '車両名',
          dataIndex: '車両名',
          scopedSlots: { customRender: 'name' },
          customCell: (text, row, index) => {
            if (!this.checkName(text['車両名'])) {
              return {
                class: 'wrongCell',
              }
            }
          },
        },
        {
          title: '車両番号',
          dataIndex: '車両番号',
          scopedSlots: { customRender: 'number' },
          customCell: (text, row, index) => {
            if (!this.checkNumber(text['車両番号'])) {
              return {
                class: 'wrongCell',
              }
            }
          },
        },
        {
          title: 'ロガー番号',
          dataIndex: 'ロガー番号',
          scopedSlots: { customRender: 'logger_id' },
          customCell: (text, row, index) => {
            if (!this.checkLoggerId(text['ロガー番号'])) {
              return {
                class: 'wrongCell',
              }
            }
          },
        },
        {
          title: '車種',
          dataIndex: '車種',
          scopedSlots: { customRender: 'car_type' },
          customCell: (text, row, index) => {
            if (!this.checkCarType(text['車種'])) {
              return {
                class: 'wrongCell',
              }
            }
          },
        },
        {
          title: 'アプリのみでの使用',
          dataIndex: 'アプリのみでの使用',
          scopedSlots: { customRender: 'only_app' },
          customCell: (text, row, index) => {
            if (!this.checkOnlyApp(text['アプリのみでの使用'])) {
              return {
                class: 'wrongCell',
              }
            }
          },
        },
        {
          title: 'メーカー',
          dataIndex: 'メーカー',
          customCell: (text, row, index) => {
            if (!this.checkManufacturer(text['メーカー'])) {
              return {
                class: 'wrongCell',
              }
            }
          },
        },
        {
          title: '型式',
          dataIndex: '型式',
          customCell: (text, row, index) => {
            if (!this.checkModel(text['型式'])) {
              return {
                class: 'wrongCell',
              }
            }
          },
        },
        {
          title: '次回法定点検日',
          dataIndex: '次回法定点検日',
          scopedSlots: { customRender: 'legal_inspection_date' },
          customCell: (text, row, index) => {
            if (!this.checkLegalInspectionDate(text['次回法定点検日'])) {
              return {
                class: 'wrongCell',
              }
            }
          },
        },
        {
          title: '車検満了日',
          dataIndex: '車検満了日',
          scopedSlots: { customRender: 'inspection_expiration_date' },
          customCell: (text, row, index) => {
            if (!this.checkInspectionExpirationDate(text['車検満了日'])) {
              return {
                class: 'wrongCell',
              }
            }
          },
        },
        {
          title: '所有形態',
          dataIndex: '所有形態',
          scopedSlots: { customRender: 'ownership' },
          customCell: (text, row, index) => {
            if (!this.checkOwnership(text['所有形態'])) {
              return {
                class: 'wrongCell',
              }
            }
          },
        },
        {
          title: '月額リース料',
          dataIndex: '月額リース料',
          customCell: (text, row, index) => {
            if (!this.checkMonthlyLeaseFee(text['月額リース料'])) {
              return {
                class: 'wrongCell',
              }
            }
          },
        },
        {
          title: 'リース満了日',
          dataIndex: 'リース満了日',
          scopedSlots: { customRender: 'lease_expiration_date' },
          customCell: (text, row, index) => {
            if (!this.checkLeaseExpirationDate(text['リース満了日'])) {
              return {
                class: 'wrongCell',
              }
            }
          },
        },
        {
          title: '購入年月日',
          dataIndex: '購入年月日',
          customCell: (text, row, index) => {
            if (!this.checkDatePurchase(text['購入年月日'])) {
              return {
                class: 'wrongCell',
              }
            }
          },
        },
        {
          title: '任意保険満了日',
          dataIndex: '任意保険満了日',
          customCell: (text, row, index) => {
            if (!this.checkInsuranceExpirationDate(text['任意保険満了日'])) {
              return {
                class: 'wrongCell',
              }
            }
          },
        },
        {
          title: 'ドライブレコーダー設置有無',
          dataIndex: 'ドライブレコーダー設置有無',
          scopedSlots: { customRender: 'drive_recorder' },
          customCell: (text, row, index) => {
            if (!this.checkDriveRecorder(text['ドライブレコーダー設置有無'])) {
              return {
                class: 'wrongCell',
              }
            }
          },
        },
        {
          title: '備考',
          dataIndex: '備考',
          scopedSlots: { customRender: 'description' },
          customCell: (text, row, index) => {
            if (!this.checkDescription(text['備考'])) {
              return {
                class: 'wrongCell',
              }
            }
          },
        },
      ],
      excelData: null,
      errorCount: 0,
    }
  },
  watch: {
    excelData: function(val) {
      const _this = this
      for (const row in val) {
        if (!_this.checkBranchId(val[row]['支店Id'])) {
          _this.errorCount++
          break
        }
        if (!_this.checkName(val[row]['車両名'])) {
          _this.errorCount++
          break
        }
        if (!_this.checkNumber(val[row]['車両番号'])) {
          _this.errorCount++
          break
        }
        if (!_this.checkLoggerId(val[row]['ロガー番号'])) {
          _this.errorCount++
          break
        }
        if (!_this.checkCarType(val[row]['車種'])) {
          _this.errorCount++
          break
        }
        if (!_this.checkOnlyApp(val[row]['アプリのみでの使用'])) {
          _this.errorCount++
          break
        }
        if (!_this.checkManufacturer(val[row]['メーカー'])) {
          _this.errorCount++
          break
        }
        if (!_this.checkModel(val[row]['型式'])) {
          _this.errorCount++
          break
        }
        if (!_this.checkLegalInspectionDate(val[row]['次回法定点検日'])) {
          _this.errorCount++
          break
        }
        if (!_this.checkInspectionExpirationDate(val[row]['車検満了日'])) {
          _this.errorCount++
          break
        }
        if (!_this.checkOwnership(val[row]['所有形態'])) {
          _this.errorCount++
          break
        }
        if (!_this.checkMonthlyLeaseFee(val[row]['月額リース料'])) {
          _this.errorCount++
          break
        }
        if (!_this.checkLeaseExpirationDate(val[row]['リース満了日'])) {
          _this.errorCount++
          break
        }
        if (!_this.checkDatePurchase(val[row]['購入年月日'])) {
          _this.errorCount++
          break
        }
        if (!_this.checkInsuranceExpirationDate(val[row]['任意保険満了日'])) {
          _this.errorCount++
          break
        }
        if (!_this.checkDriveRecorder(val[row]['ドライブレコーダー設置有無'])) {
          _this.errorCount++
          break
        }
        if (!_this.checkDescription(val[row]['備考'])) {
          _this.errorCount++
          break
        }
      }
    },
  },
  created() {
    const branchList = Vue.prototype.$api.send('get', 'branches')
    branchList.then(response => {
      this.branches = response
      this.branchListLoading = false
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': 支店の取得に失敗しました。',
        })
        this.branchListLoading = false
      })
  },
  methods: {
    next() {
      this.current++
    },
    prev() {
      this.current--
    },
    beforeUpload(file) {
      const _this = this
      _this.errorCount = 0
      console.log(file)
      const reader = new FileReader()
      reader.onload = function(file) {
        const data = new Uint8Array(file.target.result)
        const workbook = XLSX.read(data, { type: 'array' })
        console.log(workbook)
        _this.excelData = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]])
      }
      reader.readAsArrayBuffer(file)
      this.current++
      return false
    },
    handleChange(info) {
      const status = info.file.status
      if (status !== 'uploading') {
        console.log(info.file, info.fileList)
      }
      if (status === 'done') {
        this.$message.success(`${info.file.name} file uploaded successfully.`)
      } else if (status === 'error') {
        this.$message.error(`${info.file.name} file upload failed.`)
      }
    },
    checkBranchId(val) {
      if (val && Number.isInteger(val) && this.branches.find((o) => o.id === val)) {
        return true
      }
      return false
    },
    checkName(val) {
      if (!val || val.length > 255) {
        return false
      }
      return true
    },
    checkNumber(val) {
      if (!val || val.length > 255) {
        return false
      }
      return true
    },
    checkLoggerId(val) {
      if (Number.isInteger(val) && val >= 0) {
        return true
      }
      return false
    },
    checkCarType(val) {
      if (Number.isInteger(val) && val > 0 && val <= 34) {
        return true
      }
      return false
    },
    checkOnlyApp(val) {
      if (Number.isInteger(val) && val >= 0 && val <= 1) {
        return true
      }
      return false
    },
    checkManufacturer(val) {
      if (val && val.length > 1023) {
        return false
      }
      return true
    },
    checkModel(val) {
      if (val && val.length > 1023) {
        return false
      }
      return true
    },
    checkInspectionExpirationDate(val) {
      const reg = /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/
      if (val && !reg.test(val)) {
        return false
      }
      return true
    },
    checkLegalInspectionDate(val) {
      const reg = /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/
      if (val && !reg.test(val)) {
        return false
      }
      return true
    },
    checkLeaseExpirationDate(val) {
      const reg = /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/
      if (val && !reg.test(val)) {
        return false
      }
      return true
    },
    checkOwnership(val) {
      if (Number.isInteger(val) && val >= 0 && val <= 4) {
        return true
      }
      return false
    },
    checkMonthlyLeaseFee(val) {
      if (!val || Number.isInteger(val)) {
        return true
      }
      return false
    },
    checkDatePurchase(val) {
      const reg = /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/
      if (val && !reg.test(val)) {
        return false
      }
      return true
    },
    checkDateLegalInspectionDate(val) {
      const reg = /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/
      if (val && !reg.test(val)) {
        return false
      }
      return true
    },
    checkInsuranceExpirationDate(val) {
      const reg = /^[0-9]{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/
      if (val && !reg.test(val)) {
        return false
      }
      return true
    },
    checkDriveRecorder(val) {
      if (Number.isInteger(val) && val >= 0 && val <= 2) {
        return true
      }
      return false
    },
    checkDescription(val) {
      return true
    },
    createVehicle() {
      this.createLoading = true
      const result = Vue.prototype.$api.send('post', 'vehicles/bulk/create', { vehicles: this.excelData })
      result.then(response => {
        this.$notification['success']({
          message: '車両を作成しました',
        })
        this.$router.push('/company')
        this.createLoading = false
      })
        .catch(error => {
          let duration = 4.5
          if (error.status === 500) {
            duration = 15
          }
          this.$notification['error']({
            message: error.status + ': ' + error.data.data,
            duration: duration,
          })
          this.createLoading = false
        })
    },
  },
}
</script>

<style>
.wrongCell {
  background-color: #FED1D1;
}
</style>
