var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-lg-12" }, [
        _c("div", { staticClass: "card" }, [
          _c("div", { staticClass: "card-body" }, [
            _vm.branchListLoading
              ? _c("div", [_c("a-spin", { attrs: { tip: "Loading..." } })], 1)
              : _vm._e(),
            !_vm.branchListLoading
              ? _c(
                  "div",
                  [
                    _c(
                      "a-steps",
                      { attrs: { current: _vm.current } },
                      _vm._l(_vm.steps, function(item) {
                        return _c("a-step", {
                          key: item.title,
                          attrs: { title: item.title }
                        })
                      }),
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticStyle: {
                          "margin-top": "40px",
                          "padding-bottom": "30px"
                        }
                      },
                      [
                        _vm.current === 0
                          ? _c(
                              "div",
                              [
                                _c("p", [
                                  _vm._v(
                                    "テンプレートファイルをダウンロードし、作成したい車両をエクセルにて入力してください。作成しましたらページ下部よりファイルをアップロードしてください。"
                                  )
                                ]),
                                _c(
                                  "a-card",
                                  {
                                    staticStyle: { "margin-bottom": "30px" },
                                    attrs: {
                                      title: "車両の一括作成テンプレート"
                                    }
                                  },
                                  [
                                    _c(
                                      "p",
                                      {
                                        staticClass: "text-center",
                                        staticStyle: { "font-size": "1.2em" }
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "text-primary",
                                            attrs: {
                                              href:
                                                "templates/vehicle_template.xlsx"
                                            }
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fa fa-file-excel-o"
                                            }),
                                            _vm._v(" テンプレートファイル")
                                          ]
                                        )
                                      ]
                                    ),
                                    _c(
                                      "p",
                                      [
                                        _vm._v(
                                          "\n                    1行目は変更しないでください。"
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          "\n                    2行目はサンプルとしていれているので、2行目から書き換えてお使いください。"
                                        ),
                                        _c("br"),
                                        _c("br"),
                                        _vm._v("\n                    ※"),
                                        _c("strong", [
                                          _vm._v("車両名、車両番号")
                                        ]),
                                        _vm._v(
                                          "は必須項目で、255文字以下で入力してください。"
                                        ),
                                        _c("br"),
                                        _c("strong", [_vm._v("ロガー番号")]),
                                        _vm._v(
                                          "は特に指示がない場合「0」を入力してください。"
                                        ),
                                        _c("br"),
                                        _c("strong", [
                                          _vm._v(
                                            "車検満了日、購入年月日、任意保険満了日"
                                          )
                                        ]),
                                        _vm._v(
                                          "は任意項目で、YYYY-MM-DD の形式(例. 2022年2月1日の場合、2022-02-01)で入力してください"
                                        ),
                                        _c("br"),
                                        _vm._v(
                                          "\n                    以下項目の選択肢についてです。それぞれ数値を入力してください。"
                                        ),
                                        _c("br"),
                                        _c("strong", [
                                          _vm._v("アプリのみでの使用")
                                        ]),
                                        _vm._v(
                                          "は [0: アプリ以外でも使用, 1: アプリのみでの使用]"
                                        ),
                                        _c("br"),
                                        _c("strong", [_vm._v("所有形態")]),
                                        _vm._v(
                                          "は [0: 未選択, 1: リース, 2: 新車, 3: 中古, 4: その他]"
                                        ),
                                        _c("br"),
                                        _c("strong", [
                                          _vm._v("ドライブレコーダー設置有無")
                                        ]),
                                        _vm._v("は [0: 未選択, 1: 有, 2: 無]"),
                                        _c("br"),
                                        _c("strong", [_vm._v("支店ID")]),
                                        _vm._v("は ["),
                                        _vm._l(_vm.branches, function(item) {
                                          return _c(
                                            "span",
                                            { key: item.id + item.name },
                                            [
                                              _vm._v(
                                                _vm._s(item.id) +
                                                  ": " +
                                                  _vm._s(item.name) +
                                                  ", "
                                              )
                                            ]
                                          )
                                        }),
                                        _vm._v("]"),
                                        _c("br"),
                                        _c("strong", [_vm._v("車種")]),
                                        _vm._v("は ["),
                                        _vm._l(_vm.carTypes, function(item) {
                                          return _c(
                                            "span",
                                            { key: item.value },
                                            [
                                              item.value !== 0
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(item.value) +
                                                        ": " +
                                                        _vm._s(item.label) +
                                                        ", "
                                                    )
                                                  ])
                                                : _vm._e()
                                            ]
                                          )
                                        }),
                                        _vm._v("]"),
                                        _c("br")
                                      ],
                                      2
                                    )
                                  ]
                                ),
                                _c(
                                  "a-upload-dragger",
                                  {
                                    attrs: {
                                      name: "file",
                                      multiple: false,
                                      beforeUpload: _vm.beforeUpload
                                    }
                                  },
                                  [
                                    _c(
                                      "p",
                                      { staticClass: "ant-upload-drag-icon" },
                                      [
                                        _c("a-icon", {
                                          attrs: { type: "inbox" }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "p",
                                      { staticClass: "ant-upload-text" },
                                      [
                                        _vm._v(
                                          "\n                    クリックしてファイルを選択 または ファイルをドラッグ してください。\n                  "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "p",
                                      { staticClass: "ant-upload-hint" },
                                      [
                                        _vm._v(
                                          "\n                    ファイルはテンプレートに沿って作られたものに限ります。\n                  "
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.current === 1
                          ? _c(
                              "div",
                              [
                                _vm.errorCount
                                  ? _c(
                                      "h5",
                                      {
                                        staticClass: "text-danger",
                                        staticStyle: { "margin-bottom": "20px" }
                                      },
                                      [
                                        _vm._v(
                                          "入力にエラーがあります。背景に"
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "wrongCell" },
                                          [_vm._v("色がついているセル")]
                                        ),
                                        _vm._v("を確認してください。")
                                      ]
                                    )
                                  : _vm._e(),
                                _c("a-table", {
                                  staticStyle: { "margin-bottom": "30px" },
                                  attrs: {
                                    columns: _vm.columns,
                                    "data-source": _vm.excelData,
                                    "row-key": function(record) {
                                      return record.name + record.number
                                    },
                                    pagination: false
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "branch_id",
                                        fn: function(branch_id) {
                                          return _c("span", {}, [
                                            _vm._v(
                                              _vm._s(
                                                branch_id &&
                                                  _vm.branches.find(function(
                                                    o
                                                  ) {
                                                    return o.id === branch_id
                                                  })
                                                  ? _vm.branches.find(function(
                                                      o
                                                    ) {
                                                      return o.id === branch_id
                                                    }).name
                                                  : "必須項目です。"
                                              )
                                            )
                                          ])
                                        }
                                      },
                                      {
                                        key: "name",
                                        fn: function(name) {
                                          return _c("span", {}, [
                                            _vm._v(
                                              _vm._s(
                                                name ? name : "必須項目です。"
                                              )
                                            )
                                          ])
                                        }
                                      },
                                      {
                                        key: "number",
                                        fn: function(number) {
                                          return _c("span", {}, [
                                            _vm._v(
                                              _vm._s(
                                                number
                                                  ? number
                                                  : "必須項目です。"
                                              )
                                            )
                                          ])
                                        }
                                      },
                                      {
                                        key: "logger_id",
                                        fn: function(logger_id) {
                                          return _c("span", {}, [
                                            _vm._v(
                                              _vm._s(
                                                logger_id || logger_id === 0
                                                  ? logger_id
                                                  : "必須項目です。"
                                              )
                                            )
                                          ])
                                        }
                                      },
                                      {
                                        key: "car_type",
                                        fn: function(car_type) {
                                          return _c("span", {}, [
                                            _vm._v(
                                              _vm._s(
                                                car_type
                                                  ? _vm.carTypes.find(function(
                                                      o
                                                    ) {
                                                      return (
                                                        o.value ===
                                                        Number(car_type)
                                                      )
                                                    }).label
                                                  : "必須項目です。"
                                              )
                                            )
                                          ])
                                        }
                                      },
                                      {
                                        key: "only_app",
                                        fn: function(only_app) {
                                          return _c("span", {}, [
                                            _vm._v(
                                              _vm._s(
                                                only_app == 0
                                                  ? "アプリ以外でも使用"
                                                  : only_app == 1
                                                  ? "アプリのみ"
                                                  : "0 ~ 1 を入力してください"
                                              )
                                            )
                                          ])
                                        }
                                      },
                                      {
                                        key: "ownership",
                                        fn: function(ownership) {
                                          return _c("span", {}, [
                                            _vm._v(
                                              _vm._s(
                                                ownership == 0
                                                  ? "未選択"
                                                  : ownership == 1
                                                  ? "リース"
                                                  : ownership == 2
                                                  ? "新車"
                                                  : ownership == 3
                                                  ? "中古"
                                                  : ownership == 4
                                                  ? "その他"
                                                  : "0 ~ 4 を入力してください"
                                              )
                                            )
                                          ])
                                        }
                                      },
                                      {
                                        key: "drive_recorder",
                                        fn: function(drive_recorder) {
                                          return _c("span", {}, [
                                            _vm._v(
                                              _vm._s(
                                                drive_recorder == 0
                                                  ? "未選択"
                                                  : drive_recorder == 1
                                                  ? "有"
                                                  : drive_recorder == 2
                                                  ? "無"
                                                  : "0 ~ 2 を入力してください"
                                              )
                                            )
                                          ])
                                        }
                                      },
                                      {
                                        key: "description",
                                        fn: function(description) {
                                          return _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "white-space": "pre-wrap"
                                              }
                                            },
                                            [_vm._v(_vm._s(description))]
                                          )
                                        }
                                      }
                                    ],
                                    null,
                                    false,
                                    647405849
                                  )
                                }),
                                !_vm.errorCount
                                  ? _c("p", [
                                      _vm._v(
                                        "以上のデータで作成してよろしいですか？"
                                      )
                                    ])
                                  : _vm._e(),
                                _c(
                                  "div",
                                  { staticClass: "steps-action" },
                                  [
                                    !_vm.errorCount
                                      ? _c(
                                          "a-button",
                                          {
                                            attrs: {
                                              type: "primary",
                                              loading: _vm.createLoading
                                            },
                                            on: { click: _vm.createVehicle }
                                          },
                                          [
                                            _vm._v(
                                              "\n                    作成する\n                  "
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _c(
                                      "a-button",
                                      {
                                        staticStyle: { "margin-left": "8px" },
                                        on: { click: _vm.prev }
                                      },
                                      [
                                        _vm._v(
                                          "\n                    戻る\n                  "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.current === 2
                          ? _c("div", [_c("p", [_vm._v("2番目")])])
                          : _vm._e()
                      ]
                    )
                  ],
                  1
                )
              : _vm._e()
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "utils__title mb-3" }, [
      _c("strong", { staticClass: "text-uppercase font-size-16" }, [
        _vm._v("車両の一括作成")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }